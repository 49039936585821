import React from 'react';
import Hero from '../Components/Hero/Hero';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PriceSlider from '../Components/Slider/PriceSlider';
import Accordion from '../Components/Accordion/Accordion';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img1.png',
    },
  ],
  title: ['Schlaf-Impuls', 'Rücken-Impuls', 'Waldbaden-Impuls'],
};

const aboutData = {
  title:
    'Vorträge zum Thema Gesundheit für Unternehmen – Schlaf-, Rücken- und Waldbaden-Impuls für die Gesundheit deiner Mitarbeitenden',
  subTitle:
    'Seit meinem Studium der Sportwissenschaften und meiner Tätigkeit als Fitnesstrainerin widme ich mich der Gesundheit und dem Wohlbefinden des Menschen. Seit meinem 18. Lebensjahr arbeite ich im Fitness- und Gesundheitsbereich und habe dabei zahlreiche Erfahrungen gesammelt. Mein besonderes Interesse gilt dem menschlichen Körper, seinen komplexen Funktionen und der Art und Weise, wie unsere körperliche und seelische Gesundheit zusammenhängen. <br /><br />Als ausgebildete Trainerin für Wald- und Naturerleben sowie Achtsamkeit in der Natur sehe ich den Menschen als ein ganzheitliches System. Dieses Wissen und meine Faszination für den menschlichen Körper und auch seine Verbindungen zur Natur möchte ich in meinen Vorträgen weitergeben. Es ist mir ein Anliegen, dir zu zeigen, wie du deine Gesundheit ganzheitlich fördern und stärken kannst. <br /><br />"Wie in der Natur wirkt auch in unserem Körper alles im Einklang – wir müssen die Verbindung erkennen, um gesund zu bleiben."',

  avater: {
    img: 'images/avatar1.png',
    name: 'Lisa García',
    designation: 'Gesundheitsexpertin, B.A. Fitnesstraining, M.A. Sport- und Bewegungsgerontologie',
  },
  timeTable: [
    {
      day: 'Montag bis Freitag',
    },
  ],
  groupTable: [
    {
      teilnehmerUnternehmen: 'Für Unternehmen:',
    },
  ],
  groupTablePrivat: [
    {
      teilnehmerPrivat: 'Für private Gruppen:',
    },
  ],
  contact: <a
  href="mailto:info@natuerl-ich.green?subject=Anfrage%20f%C3%BCr%20Vortragsbuchung%20von%20Gesundheit-Impuls"
  >
    info@natuerl-ich.green
    </a>
    ,
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/icon6.svg',
    title: 'Schlaf-Impuls',
    subTitle:
      'Wissenschaftliche Einblicke und praktische Tipps für erholsame Nächte.\n\n' +
      'Schlaf ist der Schlüssel zu guter Gesundheit und Wohlbefinden. In diesem Online-Vortrag erfährst du, warum wir schlafen und was währenddessen im Körper passiert. Zusätzlich gibt es praktische Tipps für eine bessere Schlafhygiene, die dir helfen, deinen Schlaf zu optimieren und dein Wohlbefinden zu steigern.'
  },
  {
    bg: 'green',
    icon: 'icons/icon7.svg',
    title: 'Rücken-Impuls',
    subTitle:
      'Die Schwächen des Sitzens und was du dagegen tun kannst.\n\n' +
      'Entdecke die Anatomie deiner Rückenmuskulatur und finde heraus, welche Muskeln besonders unter langem Sitzen leiden. Ich gebe dir Tipps für deinen ergonomischen Arbeitsplatz und einfache Übungen, um deine Muskeln zu stärken und zu dehnen. Sei dabei und sorge für einen starken Rücken!'
  },
  {
    bg: 'red',
    icon: 'icons/icon8.svg',
    title: 'Waldbaden-Impuls',
    subTitle:
      'Waldbaden verstehen - Eine Reise in die Natur.\n\n' +
      'Dieser Vortrag bietet dir eine Einführung in das Konzept des Waldbadens. Wir betrachten die historischen Wurzeln und werfen wir einen Blick auf die wissenschaftlichen Studien zu seinen Auswirkungen auf die Gesundheit. Tauche ein in die Welt des Waldbadens und entdecke seine heilende Kraft!'
  },
];

const priceData = [
  {
    title: 'Preis auf Anfrage',
    price: 'Schlaf-Impuls',
    featureList: [
      {
        title: 'Wissenschaftliche Grundlagen des Schlafs',
        status: '1',
      },
      {
        title: 'Alltagsverhalten für einen guten Schlaf',
        status: '1',
      },
      {
        title: 'Optimierung der Schlafumgebung',
        status: '1',
      },
      {
        title: 'Verbesserte Schlafhygiene',
        status: '1',
      },
      {
        title: 'Entspannungsübungen',
        status: '1',
      },
    ],
  },
  {
    title: 'Preis auf Anfrage',
    price: 'Rücken-Impuls',
    featureList: [
      {
        title: 'Anatomie und Biomechanik der Wirbelsäule',
        status: '1',
      },
      {
        title: 'Ergonomie am Arbeitsplatz und zu Hause',
        status: '1',
      },
      {
        title: 'Rückenschmerzen vorbeugen im Alltag',
        status: '1',
      },
      {
        title: 'Gezielte Kräftigungsübungen',
        status: '1',
      },
      {
        title: 'Gezielte Dehnübungen',
        status: '1',
      },
    ],
  },
  {
    title: 'Preis auf Anfrage',
    price: 'Waldbaden-Impuls',
    featureList: [
      {
        title: 'Geschichte und Philosophie des Waldbadens',
        status: '1',
      },
      {
        title: 'Gesundheitliche Vorteile des Waldbadens',
        status: '1',
      },
      {
        title: 'Sinneswahrnehmung im Wald schärfen',
        status: '1',
      },
      {
        title: 'Achtsames Naturerleben',
        status: '1',
      },
      {
        title: 'Übungsimpulse',
        status: '1',
      },
    ],
  },
];

const faqData = {
  title: 'FAQS',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'Wer kann die Vorträge buchen?',
      content: `Die Vorträge können von Unternehmen, Vereinen oder auch Privatpersonen gebucht werden. Die Mindestteilnehmerzahl, damit ein Vortrag stattfinden kann, beträgt 5 Personen.`,
    },
    {
      title: 'Wie kann man die Vorträge buchen?',
      content: `Bitte kontaktiere mich per Mail, um einen Vortrag zu buchen. Bitte gib dabei dein bevorzugtes Thema, die Gruppengröße und wenn vorhanden den gewünschten Termin an, damit ich dir ein Angebot erstellen kann.`,
    },
    {
      title: 'Wie sind die Kosten für einen Vortrag?',
      content: `Die Kosten für einen Vortrag variieren je nach Themenwahl, Umfang der Inhalte und der Gruppengröße. Kontaktiere mich für ein individuelles Angebot, das auf deine spezifischen Anforderungen zugeschnitten ist.`,
    },
    {
      title: 'Sind auch andere Themen möglich?',
      content: `Ja, neben den Standardthemen wie gesundem Schlaf, Rückengesundheit und Waldbaden sind auch andere Gesundheitsthemen möglich. Kontaktiere mich, um deine spezifischen Themenwünsche zu besprechen.`,
    },
    {
      title: 'Sind auch andere Formate wie Präsenzveranstaltungen oder Workshops möglich?',
      content: `Generell sind auch andere Formate möglich, abhängig von den Voraussetzungen und individuellen Wünschen. Kontaktiere mich, um die Möglichkeiten für Präsenzveranstaltungen, Workshops und andere Formate zu besprechen.`,
    },
  ],
};

const Home = () => {
  return (
    <>
      <Hero data={heroData} />
      <About data={aboutData} />
      <Iconbox data={iconboxData} />
      <PriceSlider data={priceData} />
      <Accordion data={faqData} />
    </>
  );
};

export default Home;
