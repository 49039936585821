import React, { useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Footer = ({ data, varient }) => {
  const { logo, subTitle, bgImg, links } = data;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [scrollPosition, setScrollPosition] = useState(0);

  const [mobileToggle, setMobileToggle] = useState(false);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={`st-site-footer st-sticky-footer st-dynamic-bg ${varient ? varient : ""}`}
      style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="st-main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="st-footer-widget">
                <div className="st-text-field">
                  <img src={logo} alt={logo} className="st-footer-logo" />
                  <div className="st-height-b25 st-height-lg-b25" />
                  <div className="st-footer-text">Entdecke einfache und effektive Vortrags-Impulse für mehr Energie und Wohlbefinden. </div>
                  <div className="st-height-b25 st-height-lg-b25" />
                  
                </div>
              </div>
            </div>
            {/* .col */}
            <div className="col-lg-3">
              <div className="st-footer-widget">
                <h2 className="st-footer-widget-title">Nützliche Links</h2>
                <ul className="st-footer-widget-nav st-mp0">
                  <li>
                  <a 
                      href="https://natuerl-ich.green/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Icon icon="fa:angle-right" />
                      natürl-ich
                    </a> <br />
                    <a 
                      href="https://blog.natuerl-ich.green/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Icon icon="fa:angle-right" />
                      Blog
                    </a> <br />
                    <a 
                      href="https://www.linkedin.com/company/gesundheit-impuls" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Icon icon="fa:angle-right" />
                      LinkedIn Seite
                    </a> <br />
                  <a 
                      href="https://natuerl-ich.green/impressum.php" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Icon icon="fa:angle-right" />
                      Impressum
                    </a> <br />
                  <a 
                      href="https://natuerl-ich.green/datenschutz.php" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Icon icon="fa:angle-right" />
                      Datenschutz
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* .col */}
            <div className="col-lg-3">
              <div className="st-footer-widget">
                <h2 className="st-footer-widget-title">Vorträge</h2>
                <ul className="st-footer-widget-nav st-mp0">
                <li>
                <ScrollLink to="pricing" spy={true} duration={500} onClick={() => setMobileToggle(false)} >Gesunder Schlaf</ScrollLink> <br />
                <ScrollLink to="pricing" spy={true} duration={500} onClick={() => setMobileToggle(false)} >Rücken Gesundheit</ScrollLink> <br />
                <ScrollLink to="pricing" spy={true} duration={500} onClick={() => setMobileToggle(false)} >Waldbaden</ScrollLink>
                </li>
                </ul>
              </div>
            </div>
            {/* .col */}
            <div className="col-lg-3">
              <div className="st-footer-widget">
                <h2 className="st-footer-widget-title">Kontakt</h2>
                <ul className="st-footer-contact-list st-mp0">
                  <li>
                    <span className="st-footer-contact-title">E-Mail: </span>
                    <a 
                      href="mailto:info@natuerl-ich.green?subject=Anfrage%20f%C3%BCr%20Vortragsbuchung%20von%20Gesundheit-Impuls"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      info@natuerl-ich.green
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* .col */}
          </div>
        </div>
      </div>
      <div className="st-copyright-wrap">
        <div className="container">
          <div className="st-copyright-in">
            <div className="st-left-copyright">
              <div className="st-copyright-text">
                Copyright {currentYear} natuerl-ich.green. <br />Design by <a
              href="https://smartandpro.de" target='_blank'
            >SMART &PRO AI Services.</a>
              </div>
            </div>
            <div className="st-right-copyright">
              <div id="st-backtotop" style={{ scale: `${scrollPosition >= 100 ? "1" : "0"}` }} onClick={scrollToTop}>
                <Icon icon="fa6-solid:angle-up" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
